<template lang="pug">
  .root
    component-header-index
    div(class="container-flex")
      div(class="content-left")

        div(class="event-header") >>
          span(class="display-title") 近日開催イベント

        div( class="event-img-content-grid")
          div(v-for="item in list" class="event-img-item")
            div(class="event-img-item-label")
              div(v-if="item.type === 't3'" class="red-r-label") OD
              div(v-if="item.type === 't2'" class="orange-r-label") 準OD
            img(:src="$store.state.system.config.storage_addr_list.public + item.banner_store_path" @click="onImage(item.id)")

        div(class="nextevent-content" v-if="toshowHasMore()" @click="onContinue('toshow')") >>続きを表示

      div(class="content-right")
        pr-banner

    component-footer-index
</template>

<style src="./Index.scss" lang="scss" scoped>
</style>

<script>
import { getLiveListEventToShow } from '@/api/live'

export default {
  name: "SettingEventManageDetail",
  components: { },
  data() {
    return {
      list: [],
      pageParma:{
        limit:9,
        page:1,
      },
      pagination: {
        total: 0,
        count: 0,
        per_page: 0,
        current_page: 1,
        total_pages: 1,
      },
    }
  },
  props: {},
  computed: {},
  watch: {},
  mounted() {},
  async created() {
    await this.init();
  },
  updated() {},
  destroyed() {},
  methods: {
    async init() {
      const ret = await getLiveListEventToShow(this.pageParma.limit, this.pageParma.page);
      this.list = ret.data.data;
      if( ret.data.meta ){
        this.pagination = ret.data.meta.pagination;
      }
    },
    toshowHasMore() {
      if(this.pagination.total > this.list.length){
        return true
      }
      return false
    },
    onImage(id) {
      this.$router.push({ name: 'SettingEventManageDetail', params: {
        id
      } });
    },
    async onContinue(liveType) {
      if(liveType == "toshow") {
        this.nextPage = this.pagination.current_page + 1
        this.currentPages = this.nextPage
        const ret = await getLiveListEventToShow(this.pageParma.limit, this.nextPage);
        this.list.push.apply(this.list,ret.data.data);
      }
    },
  }
};
</script>
